import { toastController } from "@ionic/vue";

//Toast to show if login isn't successfull
export async function openToast(message, cssClass) {
    const toast = await toastController.create({
        message: message,
        duration: 2500,
        cssClass: cssClass,
        animated: true,
    });
    return toast.present();
}
