import Localbase from 'localbase';

export const dbUser = new Localbase("user");
dbUser.config.debug = false;

export const dbCustomers = new Localbase("clienti");
dbCustomers.config.debug = false;

export const dbTickets = new Localbase("tickets");
dbTickets.config.debug = false;

export const dbRichieste = new Localbase("richieste");
dbRichieste.config.debug = false;

export const dbDocuments = new Localbase("documents");
dbDocuments.config.debug = false;

export const dbPresenze = new Localbase("presenze");
dbPresenze.config.debug = false;

export const dbTimbrature = new Localbase("timbrature");
dbTimbrature.config.debug = false;


//Add user to user collection
export async function setUser(user) {
    await dbUser.collection('user').add(user, user.users_id)
    //console.log('user added!')
}
//Add all customers to clienti collection
export function setCustomers(customers) {
    dbCustomers.collection('clienti').add(customers, customers.customers_id);
    //console.log('customers added');
}
//Add all customers to clienti collection
export function setTickets(tickets) {
    dbTickets.collection('tickets').add(tickets, tickets.tickets_id);
    //console.log('tickets added');
}
//Add all documents to dbDocuments collection
export function setDocuments(documents) {
    dbDocuments.collection('documents').add(documents, documents.documenti_dipendenti_id);
    //console.log('documents added');
}
//Add all documents to dbrichieste collection
export function setRichieste(richieste) {
    dbRichieste.collection('richieste').add(richieste, richieste.richieste_id);
    //console.log('richieste added');
}
//Add all presenze to dbPresenze collection
export function setPresenze(presenze) {
    dbPresenze.collection('presenze').add(presenze, presenze.timesheet_id);
    //console.log('richieste added');
}
//Add all timbrature to dbTimbrature collection
export function setTimbrature(timbrature) {
    dbTimbrature.collection('timbrature').add(timbrature, timbrature.timesheet_id);
    console.log('timbrature added');
}



//Get all customers from clienti collection
export async function getCustomers() {
    try {
        const customers = await dbCustomers.collection('clienti').get();
        //console.log('customers from indexeddDB: ', customers)
        return customers;
    }
    catch (error) {
        console.log('error: ', error)
    }
}
//Get customer by customers_id
export async function getCustomer(id) {
    try {
        const customer = await dbCustomers.collection('clienti').doc({ customers_id: id }).get();
        //console.log('customers: ', customer)
        return customer;
    }
    catch (error) {
        console.log('error: ', error)
    }
}
//Get ticket fot this user
export async function getTickets() {
    try {
        const tickets = await dbTickets.collection('tickets').collection('tickets').get();
        //console.log('tickets: ', tickets)
        return tickets;
    }
    catch (error) {
        console.log('error: ', error)
    }
}



//Get all documents from dbDocuments collection
export async function getDocuments() {
    try {
        const documents = await dbDocuments.collection('documents').orderBy('documenti_dipendenti_creation_date', 'desc').get();
        //console.log('documents from indexeddDB: ', documents)
        return documents;
    }
    catch (error) {
        console.log('error: ', error)
    }
}
//Get all richieste from dbRichieste collection
export async function getRichieste() {
    try {
        const richieste = await dbRichieste.collection('richieste').orderBy('richieste_creation_date', 'desc').get();
        //console.log('richieste from indexeddDB: ', richieste)
        return richieste;
    }
    catch (error) {
        console.log('error: ', error)
    }
}
//Get all presenze from dbPresenze collection
export async function getPresenze() {
    try {
        const presenze = await dbPresenze.collection('presenze').orderBy('timesheet_creation_date', 'desc').get();
        //console.log('presenze from indexeddDB: ', presenze)
        return presenze;
    }
    catch (error) {
        console.log('error: ', error)
    }
}
//Get all timbrature from dbTimbrature collection
export async function getTimbrature() {
    try {
        const timbrature = await dbTimbrature.collection('timbrature').orderBy('timesheet_creation_date', 'desc').get();
        console.log('richieste from indexeddDB: ', timbrature)
        return timbrature;
    }
    catch (error) {
        console.log('error: ', error)
    }
}
//Get timbratura and set end time
export async function getTimbratura(id) {
    try {
        const timbratura = await dbTimbrature.collection('timbrature').doc({ timesheet_id: id }).get();
        //console.log('timbratura: ', timbratura)
        return timbratura;
    }
    catch (error) {
        console.log('error: ', error)
    }
}


//Get document by documents_id
export async function getDocument(id) {
    try {
        const document = await dbDocuments.collection('documents').doc({ documenti_dipendente_id: id }).get();
        console.log('document: ', document)
        return document;
    }
    catch (error) {
        console.log('error: ', error)
    }
}