
import { useRouter } from "vue-router";

import { IonMenu, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonList, IonLabel, IonIcon, menuController } from "@ionic/vue";

import {
    documents,
    time,
    person,
    clipboard,
    car,
    copy,
    ticket,
    wallet,
    card,
    people,
    book,
    informationCircle,
    documentText,
    listCircle,
    hourglass,
    newspaper,
    calendar,
    cube,
} from "ionicons/icons";

export default {
    components: {
        IonMenu,
        IonHeader,
        IonContent,
        IonToolbar,
        IonTitle,
        IonItem,
        IonList,
        IonLabel,
        IonIcon,
    },
    setup() {
        const router = useRouter();

        const changePage = (pageName) => {
            menuController.close("app-menu");
            router.push(`${pageName}`);
        };

        return {
            documents,
            time,
            person,
            clipboard,
            hourglass,
            car,
            router,
            copy,
            documentText,
            ticket,
            wallet,
            people,
            listCircle,
            card,
            book,
            newspaper,
            calendar,
            cube,
            informationCircle,
            changePage,
        };
    },
};
